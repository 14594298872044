<template>
  <div class="my-profile">
    <v-container>
      <div class="main-container">  
        <v-row no-gutters>
          <v-col
            cols="12"
            sm="12"
            md="8"
            lg="9"
          >
            <v-card
              rounded="lg"
              class="pa-9"
              :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
            >
              <h2>{{ 'profile.section.left.title' | text }}</h2>
              <v-alert
                dismissible
                type="error"
                class="mt-4"
                v-model="isShowAlert"
              >{{ alertMsg }}</v-alert>
              <div class="mt-4 my-profile__content">
                <div class="mr-4 mb-4">
                  <v-img
                    :src="profileImage"
                    width="120"
                    height="auto"
                    lazy-src="@/assets/imgs/icon-profile.png"
                    @error="handleImage($event)"
                  ></v-img>
                </div>
                <div class="my-profile__list">
                  <v-row 
                    no-gutters
                    class="mb-4"
                    v-for="item in profileList"
                    :key="item.title"
                  >
                    <v-col
                      cols="3"
                      sm="3"
                      md="3"
                      lg="2"
                    >
                      <p>{{ item.title | text }}</p>
                    </v-col>
                    <v-col
                      cols="9"
                      sm="9"
                      md="9"
                      lg="10"
                    >
                      <p>{{ `: ${item.value}` }}</p>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="4"
            lg="3"
          >
            <v-card
              rounded="lg"
              class="pa-9 my-profile__section_add"
              :class="{'py-9 px-4 mt-4 ml-0': $vuetify.breakpoint.smAndDown}"
            >
              <p>{{ 'profile.section.right.title' | text }}</p>
              <v-btn color="primary" class="btn-custom_sm my-4" large @click="goToChangePassword">
                {{ 'profile.section.right.btn' | text}}
              </v-btn>
              <p>{{ 'profile.section.right.desc' | text }}</p>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
// Libs
import { get } from 'dot-prop'

import {
  CHANGE_PASSWORD
} from '@/constants/pages'

  export default {
    name: 'MyProfile',
    data() {
      return {
        isShowAlert: false,
        alertMsg: '',
        isImgError: false,
        defaultImg: '@/assets/imgs/icon-profile.png'
      }
    },
    head: {
      link: [
        { rel: 'canonical', href: 'https://admisi.sttaa.ac.id/profil', id: 'canonical' },
      ]
    },
    computed: {
      profile () {
        return get(this.$store.state, 'web.profile', {})
      },
      profileList () {
        return [{
          title: 'profile.section.left.name',
          value: get(this.profile, 'name', '-')
        }, {
          title: 'profile.section.left.email',
          value: get(this.profile, 'email', '-')
        }, {
          title: 'profile.section.left.phone',
          value: get(this.profile, 'phone', '-')
        }]
      },
      profileImage () {
        const img= get(this.profile, 'image', '@/assets/imgs/icon-profile.png')
        return this.isImgError ? this.defaultImg : img
      }
    },
    created () {
      if (!this.profile.name) {
        this.getProfile()
      }
    },
    methods: {
      getProfile () {
        this.$store.dispatch('web/getProfile').then(res => {
          if (!res.success) return Promise.reject(res)
        }).catch(error => {
          const message = get(error, 'resultInfo.resultMsg', 'Error System')
          this.handleAlert(true, message)
        })
      },
      handleAlert (isShowAlert, msg) {
        this.isShowAlert = isShowAlert
        this.alertMsg = msg
      },
      handleImage (event) {
        this.isImgError = true
      },
      goToChangePassword () {
        this.$router.push({
          name: CHANGE_PASSWORD
        }).catch(() => {})
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.my-profile {
  min-height: 500px;
  &__list {
    width: -webkit-fill-available;
  }
  &__section_add {
    margin-left: 36px;
    text-align: center;
  }
  p {
    margin: 0;
  }
  &__content {
    display: flex;
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    &__content {
      flex-direction: column;
    }
  }
}
</style>